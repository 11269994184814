import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Img from "gatsby-image"

import { Text } from "../atoms/text"
import OptionalLink from "../contentTemplates/optionalLink"

const ImageRight = ({
  amenity_title,
  amenity_text,
  link,
  link_label,
  image,
  document_pdf,
  document_label,
}) => {
  return (
    <Container>
      <Content>
        <div>
          <Title dangerouslySetInnerHTML={{ __html: amenity_title.html }} />
          <Text>{amenity_text.text}</Text>
        </div>
        <div style={{ padding: "0 0 24px 0" }}>
          {document_pdf.url !== "" && (
            <OptionalLink
              url={document_pdf.url}
              type="Web"
              text={document_label}
            />
          )}
        </div>
        {!link.link_type || link.link_type === "Any" ? null : (
          <>
            {link.url !== "" && (
              <OptionalLink
                url={link.url}
                type={link.link_type}
                text={link_label}
              />
            )}
          </>
        )}
      </Content>
      <ImageContainer>
        <Img fluid={image.fluid} alt={image.alt || "amenity"} />
      </ImageContainer>
    </Container>
  )
}

export default ImageRight

export const Container = styled.div`
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 750px) {
    grid-template-columns: 1fr;
  }
  grid-column-start: 1;
  grid-column-end: 4;
`
export const Content = styled.div`
  padding-right: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
export const Title = styled.div`
  h2 {
    margin-top: 1rem;
    font-family: "Moderat Bold", sans-serif;
    text-transform: capitalize;
    color: var(--primary-text);
  }
`
export const InternalLink = styled(Link)`
  text-decoration: none;
  color: var(--primary-text);
  text-transform: capitalize;
  font-family: "Moderat Bold", sans-serif;
  width: max-content;
`
export const ExternalLink = styled.a`
  text-decoration: none;
  color: var(--primary-text);
  text-transform: capitalize;
  font-family: "Moderat Bold", sans-serif;
  width: max-content;
`
export const ImageContainer = styled.div`
  max-width: 579px;
  min-width: 400px;
  .gatsby-image-wrapper {
    height: 100%;
    max-height: 400px;
    width: 100%;
  }
  @media (max-width: 750px) {
    grid-row-start: 1;
    min-width: 300px;
    margin-bottom: 24px;
  }
`
export const ReadMoreIcon = styled.img`
  width: 5px;
  height: 10px;
  margin: 0 0 -1px 10px;
`
