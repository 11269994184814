import React from "react"
import styled from "styled-components"

import ImageRight from "./ImageRight"
import ImageLeft from "./imageLeft"
import OneThirdScreen from "./oneThird"
import TwoThirdScreen from "./twoThird"
import Fullscreen from "./fullscreen"

const Amenities = ({ amenities }) => {
  return (
    <Container>
      {amenities.map((amenity, i) => {
        if(!amenity.span) {
            return null; 
        }
        switch (amenity.span.toLowerCase()) {
          case "image right": {
            return <ImageRight key={i} {...amenity} />
          }
          case "image left": {
            return <ImageLeft key={i} {...amenity} />
          }
          case "one third screen": {
            return <OneThirdScreen key={i} {...amenity} />
          }
          case "two third screen": {
            return <TwoThirdScreen key={i} {...amenity} />
          }
          case "fullscreen": {
            return <Fullscreen key={i} {...amenity} />
          }
        }
        return null
      })}
    </Container>
  )
}

export default Amenities

const Container = styled.div`
  display: grid;
  grid-gap: 80px;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 40px 0 120px 0;
  width: 80%;
  margin: 0 auto;
  @media (max-width: 750px) {
    grid-template-columns: 1fr;
    grid-gap: 24px;
  }
`
